<template>
<!-- 引用页 -->
    <div class="Reference"
    v-loading="loadings"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- 内容 -->
        <!-- <div class="Reference_content"> -->
            <!-- 导航 -->
            <!-- <span class="img"></span> -->
            <!-- <span>系统正在加载中......</span> -->
      <!-- </div> -->
    </div>
</template>
<script>
export default {
    data(){
        return{
            loadings:true
        }
    },
    created(){
        this.loadings=true
    },
     methods:{

 }
}

</script>
<style scoped>
.Reference{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color:rgba(54, 54, 54, 0.4);
        
}
/* .Reference_content{
            position: absolute;
            display: block;
            font-size: 20px;
            line-height: 50px;
            top: 50%;
            left: 50%;
            width: 20%;
            height: 100px;
            -webkit-transform: translateY(-50%)  translateX(-50%); 
            transform: translateY(-50%)  translateX(-50%);
            color: rgb(5, 5, 5);
} */
/* .img{
    width: 100%;
    height:100%;
    display: inline-block;
   background-repeat: no-repeat;
    background-image: url("~@/assets/timg (2).gif");
    background-size: 100% 100%;
} */
</style>