<template>
  <!-- 创建团队 -->
  <div class="establish">
    <!-- 头部 -->
    <span class="top"></span>

    <!-- </img> -->
    <!-- 中间创建 -->
    <div class="center">
      <div class="left">
        <div class="left_center">
          <el-form
            :rules="rules"
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="团队名称" prop="teamName">
              <el-input
                v-model="ruleForm.teamName"
                @change="inputteamName()"
                class="input"
              ></el-input>
              <span class="tijiao">提交以后不可更改</span>
            </el-form-item>
            <el-form-item label="团队头像" prop="img">
              <div class="button-block">
                <!-- <el-button  class="xuanze" type="primary" @click="selectImg">选择图片<label  for="selImg"></label></el-button> 
                           <input type="file" id="selImg" ref="img"  @change="selectImg"> 
                           <img :src="ruleForm.img" width="200px" height="200px"> -->
                <el-upload
                  :action="address"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG"
                  list-type="picture-card"
                  :on-success="PICsuccess"
                  :on-remove="handleRemove"
                  :limit="1"
                  class="Imege"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="团队分类" prop="teamType">
              <el-select
                v-model="ruleForm.teamType"
                placeholder="请选择团队分类"
                class="input"
                :disabled='type?true:false'
              >
                <el-option
                  v-for="item in teamTypes"
                  :label="item"
                  :key="item.id"
                  :value="item"
                ></el-option>
              </el-select>
              <span class="tijiao">提交以后不可更改</span>
            </el-form-item>


            <el-form-item label="邀请专家" prop="teamType" v-if="type">
              <el-select placeholder="请选择专家" multiple collapse-tags v-model="selectTd" >
                <el-option
                  v-for="(item,i) in options"
                  :label="item.name"
                  :key="item.id"
                  :value="i"
                >
                <span style="float: left">{{ item.name}}</span>
                <span style="float: left;color: #8492a6;font-size: 13px;">{{`\xa0\xa0\xa0回答数:${item.wdAnswerNum}\xa0\xa0\xa0被采纳数:${item.wdAcceptNum}\xa0\xa0\xa0发布文章数:${item.articleNum}`}}</span>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="团队宣言" prop="teamVow">
              <el-input v-model="ruleForm.teamVow" @change="inputteamVow()">
              </el-input>
            </el-form-item>
            <el-form-item label="团队简介" prop="teamIntro">
              <el-input
                @change="inputteamIntro()"
                type="textarea"
                rows="5"
                placeholder="创建后不可更改"
                v-model="ruleForm.teamIntro"
              ></el-input>
            </el-form-item>
            <el-form-item label="建团时间">
              <label style="color:rgb(126, 125, 125)" id="time1">
                {{ nowDate + " " + nowTime + " " }}</label
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()"
                >立即创建</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="right">
        <span>创建提醒</span>
        <ul>
          <!-- <li><span class="blue">•</span><span>采纳率需要达到<span class="blue">40%</span></span></li>
                    <li><span class="blue">•</span><span>同分类只能加入<span class="blue">1</span>个团队</span></li> -->
          <li>
            <span class="blue">•</span
            ><span>最多加入<span class="blue">10</span>个团队</span>
          </li>
          <li>
            <span class="blue">•</span><span>有违规行为用户不能建团</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="loading">
      <V-Loading></V-Loading>
    </div>
  </div>
</template>
<script>
import { QUERY, QUERYED, UPDATE, DELETE, INSERT } from "@/services/dao.js";
import { post } from "@/services/http.js";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import { integralAddTen } from "@/js/common/index.js";
import axios from "axios";
export default {
  data() {
    return {
      type:'',  //是否为撮合团队   1：是
      issueId:'',   //问题id
      centersED: "", ///中间转换
      loading: false,
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      time: "",
      user: 0,
      team: 0,
      address: "", //访问地址
      ruleForm: {
        img: "",
        teamName: "",
        teamType: "",
        teamVow: "",
        teamIntro: "",
      },
      teamTypes: [],
      rules: {
        teamName: [
          { required: true, message: "请输入团队名称", trigger: "blur" },
          { max: 7, message: "长度在 7字以內", trigger: "blur" },
        ],
        teamType: [
          { required: true, message: "请选择团队分类", trigger: "change" },
        ],
        img: [{ required: true, message: "请上传头像", trigger: "change" }],
        teamVow: [
          { required: false, message: "请输入团队宣言", trigger: "blur" },
          { max: 15, message: "长度在 15字以內", trigger: "blur" },
        ],
        teamIntro: [
          { required: false, message: "请输入团队公告", trigger: "blur" },
          { max: 500, message: "长度在 500字以內", trigger: "blur" },
        ],
      },


      options: [], //专家列表
      selectTd:[],   //选择专家
    };
  },
  components: {
    "V-Loading": Loading, //正在加载中
  },
  created() {
    this.address = this.api.LoginURL.concat(
      "/resources/trading/resourcesUpload"
    );
    this.type = this.$route.query.type||''
    this.issueId = this.$route.query.issueId||''
    this.ruleForm.teamType = this.$route.query.problemClassification||''
    setInterval(this.getDate, 500);
    // this.time=document.getElementById("time1").innerHTML;
    //alert(this.time)
    //显示加载分类
    this.getZjList()
    this.inii();
  }, // 销毁定时器
  methods: {

    ///敏感词过滤
    async centerd(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.centersED = response.data.message.filterContent;
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    ///团队名称
    async inputteamName() {
      await this.centerd(this.ruleForm.teamName); //敏感词
      this.ruleForm.teamName = this.centersED; //赋值
    },
    ///团队宣言
    async inputteamVow() {
      await this.centerd(this.ruleForm.teamVow); //敏感词
      this.ruleForm.teamVow = this.centersED; //赋值
    },
    ///团队简介
    async inputteamIntro() {
      await this.centerd(this.ruleForm.teamIntro); //敏感词
      this.ruleForm.teamIntro = this.centersED; //赋值
    },
    //上传图片成功后返回的数据（图片）
    PICsuccess(file) {
      this.ruleForm.img = file.data.url;
    },
    ////加载分类
    async inii() {
      let info = await QUERYED("post", "", "TYPE {id  type}");
      for (let i = 0; i < info.data.TYPE.length; i++) {
        this.teamTypes.push(info.data.TYPE[i].type);
      }
    },

    showMessage(message,type){
      this.$message({
        type: type||"warning",
        message
      });
    },

    ///创建团队
    async submitForm() {
      let token = localStorage.getItem("Authorization");
      this.time = document.getElementById("time1").innerHTML;
      this.user = this.$store.state.userinfo.id;
      let data = "";
      let info = "";
      let datas = "";
      if(!token||!this.user||this.user<0) {
        this.showMessage("您还未登录，请您先登录！");
        this.$router.push({ path: "/login" });
        return  
      } 
      if(this.ruleForm.teamName.length > 7) {
        this.showMessage("团队名称长度在7字以內！");
        return  
      }
      if(this.ruleForm.teamIntro.length > 500) {
        this.showMessage("团队公告长度在500字以內！");
        return
      }
      if(this.ruleForm.teamVow.length > 15) {
        this.showMessage("团队宣言长度在15字以內！");
        return
      } 
      if(this.ruleForm.teamName == "") {
        this.showMessage("团队名称必填！");
        return
      } 
      if((this.ruleForm.teamType != "") == "") {
        this.showMessage("团队分类必填！");
        return
      } 
      if((this.ruleForm.img != "") == "") {
        this.showMessage("团队头像必填！");
        return
      }
      if(this.type){
        if(this.selectTd.length<=0){
          this.showMessage("请选择要邀请的专家");
          return
        }
      }
      ///查询加入或者创建了多少个团队
      datas = await QUERYED(
        "post",
        "",
        " WDTeamMember_aggregate(where: {memberId: {_eq: " +
          this.user +
          "}}) { aggregate {   count  }  }"
      );
      if (datas.data.WDTeamMember_aggregate.aggregate.count < 10) {
        this.loading = true; //显示遮罩层
        //创建团队
        data = await INSERT(
          "post",
          "",
          'insert_WdTeam(objects: {teamName:"' +
            this.ruleForm.teamName +
            '" , teamType:"' +
            this.ruleForm.teamType +
            '" , teamIntro:"' +
            this.ruleForm.teamIntro +
            '" , teamImg:"' +
            this.ruleForm.img +
            '" , teamVow:"' +
            this.ruleForm.teamVow +
            '", createTime: "' +
            new Date() +
            '"}) {affected_rows returning{id} }'
        );
        console.log('创建团队',data.data);
        if (data.data.insert_WdTeam.affected_rows > 0) {
          this.team = data.data.insert_WdTeam.returning[0].id;
          let wdTeamId = data.data.insert_WdTeam.returning[0].id
          this.loading = false; //隐藏遮罩层
          // 加入团队成员表
          info = await INSERT(
            "POST","",`insert_WdTeamMember(objects:{Leader:"团长",joinTime:"${new Date()}",member:${this.user},team:${wdTeamId}}) {affected_rows returning{id}} `
          );
          console.log('团长加入团队',info.data);
          if (info.data.insert_WdTeamMember.affected_rows > 0) {
            this.showMessage("创建成功！","success");
            if(this.type){
              // 撮合团队
              let then = this

              //竞价表添加数据
              // let info = await INSERT(
              // "post",
              // "",
              // 'insert_WdCompetePrice(objects: {answerContent: "' +
              //   name +
              //   '", answerDatetime: "' +
              //   new Date() +
              //   '", answerFiles: "", answerStatus: 0,question: ' +
              //   dataas.data.insert_WdAnswerRef.returning[0].id +
              //   ', isSuccess: 0, isInvied: 0, competeTime: "' +
              //   new Date() +
              //   '", competeType: 0, competeExpert: 0, competePay: ' +
              //   this.manyBidding +
              //   "}) {    affected_rows    returning {      id    }  }"
              // );



              async function createWdInvitation(list,num){
                if(num>=list.length){
                  //跳转到团队详情
                  then.$router.push({path: "/team-members",query: {id: wdTeamId,},});
                  return
                }
                let obj = then.options[list[num]]
                let aa = await INSERT(
                  "POST","",`insert_WdInvitation(objects:{team:${wdTeamId},user:${then.user},userid:${obj.id},createtime:"${new Date()}",questioner:${then.issueId},classification:1}) {affected_rows returning{id}} `
                );
                console.log('WdInvitation',aa.data);
                let bb = await INSERT(
                  "POST","",`insert_MessageNotification(objects:{type:1,userid:${obj.id},question:${then.issueId},creatime:"${new Date()}",state:8,TeamName:"${then.ruleForm.teamName}",beuserid:${then.user},teamType:"${then.ruleForm.teamType}",teamVow:"${then.ruleForm.teamVow}"}){affected_rows returning{id}} `
                );
                console.log('发送消息通知',bb.data);
                num++
                createWdInvitation(list,num)
              }
              createWdInvitation(this.selectTd,0)
            }else{
              // 普通创建团队
              //创建成功加10分
              integralAddTen(this.user);
              this.loading = false; //隐藏遮罩层
  
              //跳转到团队详情
              this.$router.push({
                path: "/team-members",
                query: {
                  id: wdTeamId,
                },
              });
            }
          }
        } else {
          this.showMessage("创建失败！","error");
        }
      } else {
        this.showMessage( "创建和加入的团队不能超过10个！");
      }
    },

    //   图片的上传
    async selectImg(e) {
      let inputDOM = this.$refs.img;
      if (!inputDOM.files[0]) {
        return;
      }
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        this.ruleForm.img = URL.createObjectURL(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: '格式不正确,请上传图片文件',
          type: 'warning'
        });
      }
    },

    getDate: function() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();

      _this.nowTime = hh + ":" + mf;
      _this.nowDate = yy + "/" + mm + "/" + dd;
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.ruleForm.img = "";
    },

    //获取专家
    getZjList(){
      let fontData = new FormData()
      fontData.append('industry',this.$route.query.problemClassification)
      post('/common/tjzj',fontData).then(res=>{
        this.options = res.data
      })

    }
  },
 
  beforeDestroy: function() {
    if (this.getDate) {
      clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>
<style scoped>
.establish {
  width: 100%;
  /* border: 1px solid red; */
  display: inline-block;
}
.top {
  width: 100%;
  height: 295px;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("http://www.izhihui.net/bak/resources/trading/upload/png/%E5%9B%BE%E5%B1%82_15.png");
  /* background-image: url(this.api.LoginURL.concat()+"/resources/trading/upload/png/QQ图片20200925153625_1604471981180.png"); */
  background-size: 100% 450px;
  /* border: 1px solid red; */
  overflow: hidden;
  border: 0;
}
.center {
  width: 100%;
  /* border: 1px solid red; */
  display: inline-block;
}
.left {
  display: inline-block;
  width: 60%;
  /* border: 1px solid darkgreen; */
}
.right {
  /* display: inline-block; */
  width: 350px;
  height: 350px;
  vertical-align: top;
  /* border: 1px solid fuchsia; */
  background-image: url("../../../../assets/ellipse.png");
  background-repeat: no-repeat;
  background-size: 350px 350px;
  border-radius: 50%;
  /* margin-left: 30px; */
  float: right;
  margin-right: 10px;
  position: relative;
  top: 30px;
}
.left_center {
  width: 60%;
  /* border: 1px solid slateblue; */
  /* text-align: right; */
  /* margin-right: 0; */
  float: right;
  padding-top: 30px;
}
.button-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin-right: 10px;
}
#selImg {
  display: none;
}
.xuanze {
  position: relative;
  /* top: -160px; */
  left: -20px;
}
.tijiao {
  color: red;
  font-size: 10px;
}
.input {
  width: 250px;
  margin-right: 5px;
}
.right > span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 132, 255, 1);
  /* margin-left: 25px; */
  position: relative;
  top: 100px;
  left: 100px;
}
.right > ul {
  position: relative;
  top: 100px;
  left: 65px;
}
.right > ul > li {
  font-size: 14px;
  line-height: 30px;
  height: 30px;
}
.blue {
  color: #0084ff;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
